import { fetchJson } from '@/utils/fetch'

import { Activity, ActivityFamily, Config } from '../types'

const BASE_URI = '/api/employees'

const EmployeesApiService = {
  getConfig() {
    return fetchJson<Config>('GET', `${BASE_URI}/config`)
  },
  getActivities() {
    return fetchJson<Activity[]>('GET', `${BASE_URI}/activities`)
  },
  getActivityFamilies() {
    return fetchJson<ActivityFamily[]>('GET', `${BASE_URI}/activity-families`)
  },
}

export { EmployeesApiService }
